@use 'styles/colors' as *;

.rcp {
  border-radius: 10px 10px 0 0;
}

.color-float {
  position: absolute;
  z-index: 100;

  &.top {
    transform: translate(0, calc(-100% - 3.25em));

    &:after {
      width: 0;
      height: 0;
      margin-left: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      content: '';
      display: block;
      border-top: 10px solid $primary;
    }
  }

  .rcp-fields-element-label {
    color: white;
  }
}

.button.color-picker {
  width: 256px;
  border-radius: 0 0 10px 10px;
  padding: 0.5rem 0;
}

.color-container {
  display: flex;
  align-content: center;
  position: relative;

  .colourText {
    margin-left: 0.5rem;
    display: block;
    p {
      margin: 0;
      font-size: 1.2em;
      font-weight: bolder;
      &.small {
        font-size: 0.8rem;
        font-weight: normal;
        color: lighten($darkgray, 50%);
        margin-top: 0.015rem;
        line-height: 0.8rem;
      }
    }
  }
}

.color-preview {
  background: $lightgray;
  width: 3em;
  height: 3em;
  border-radius: 3px;
}

.form-angle-input {
  max-width: 8rem;
  position: relative;

  text {
    font-size: 1.5rem;
    font-weight: bolder;
  }

  .label {
    font-size: 1.25rem;
    color: $lightgray;
  }
}

.side-by-side {
  display: flex;
  align-items: center;
  .left {
    margin-right: 2rem;
  }
  .right {
    margin-top: -2rem;
    margin-bottom: -1rem;
  }
}

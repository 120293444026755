@use 'styles/colors';

.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 3.4em);

  &.white {
    color: white;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    margin: 0;
    font-size: 1.2em;
    padding: 3em;
  }
}

.now-loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 3.4em);

  svg {
    height: 3rem;
    width: auto;
    margin-bottom: 2rem;
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0 0 2rem 0;
    font-size: 1.2em;
  }

  .button {
    background: colors.$primary;

    P {
      margin: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .create-design-advice {
    width: 50%;
  }

  .floating-right {
    position: fixed;
    right: 10vw;
    top: 5vw;
  }
}

@use 'styles/preview';

.preview-content-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  margin: 0;

  .preview-content-block-inner {

    p {
      font-size: calc(preview.$previewVh * 1.2);
      margin: calc(preview.$previewVh * 1) 0;

      strong {
        font-size: calc(preview.$previewVh * 1.2) !important;
      }
    }
  }
}

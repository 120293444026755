@use 'styles/colors';

.feature {
  display: flex;
  background: colors.$white;
  margin-top: 0.5rem;

  .details {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0.5rem;

    p {
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-size: 0.8rem;
    }
  }

  .icons {
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .shared-icon {
      svg {
        margin: 0.5rem;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

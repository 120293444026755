@use 'styles/colors';

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: colors.$darkgray;

  h3 {
    font-size: 5rem;
    margin: 0;
  }

  h5 {
    font-size: 1.5rem;
    margin: 0 0 2rem 0;
  }

  img {
    height: 15rem;
    width: auto;
  }
}

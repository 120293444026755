@use 'styles/preview';

.preview-header {
  background: #ffffff;
  font-size: 0.7rem;
  font-family: 'Montserrat', sans-serif;
  z-index: 3;

  .preview-header-top {
    width: 100%;
    padding: 0 preview.$previewPadding;
    display: grid;
    grid-template-columns: 1fr 1fr;

    p {
      display: inline-block;
      background: #f1f1f1;
      padding: calc(preview.$previewPadding * 0.7);
      border-radius: 0 0 preview.$previewBorderRadius
        preview.$previewBorderRadius;
      margin: 0;
      font-size: preview.$previewVh;
    }

    .preview-header-icon {
      border-radius: 0 0 preview.$previewBorderRadius
        preview.$previewBorderRadius;
      padding: calc(preview.$previewPadding / 2);

      svg {
        height: calc(preview.$previewVh * 2);
        width: auto;
      }
    }

    .preview-header-home {
      flex: 1;
    }

    .preview-header-buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: preview.$previewVh;
    }
  }

  .preview-header-bottom {
    padding: preview.$previewPadding;

    img {
      height: calc(preview.$previewPadding * 2.5);
      width: auto;
    }
  }
}

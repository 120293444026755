@use 'styles/preview';

.preview-interest {
  height: 100%;
  color: white;

  h1 {
    margin: calc(preview.$previewVh * 3) 0 preview.$previewVh 0;
    font-size: calc(preview.$previewVh * 3);
  }

  hr {
    width: calc(preview.$previewVh * 10);
    display: block;
    text-align: right;
    margin: calc(preview.$previewVh * 2) 0;
  }

  p {
    font-size: calc(preview.$previewVh * 1.5);
  }

  svg {
    padding: calc(preview.$previewVh * 1);
    background: white;
    margin-top: calc(preview.$previewVh * 2);
    height: calc(preview.$previewVh * 20);
    width: calc(preview.$previewVh * 20);
  }
}

@use 'styles/colors';

.error-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: colors.$red;
  text-align: center;
  overflow: hidden;
  color: white;
  font-size: 1rem;

  p {
    z-index: 10;
    position: relative;
  }

  .error-pos {
    position: relative;
    z-index: 9;

    .error-timer {
      position: absolute;
      top: -10rem;
      left: 0;
      height: 20rem;
      background: rgba(0, 0, 0, 0.3);
      width: 20%;
      content: '';
      display: block;
    }
  }
}

.success-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: colors.$green;
  text-align: center;
  overflow: hidden;
  color: white;
  font-size: 1rem;

  p {
    z-index: 10;
    position: relative;
  }

  .error-pos {
    position: relative;
    z-index: 9;

    .error-timer {
      position: absolute;
      top: -10rem;
      left: 0;
      height: 20rem;
      background: rgba(0, 0, 0, 0.3);
      width: 20%;
      content: '';
      display: block;
    }
  }
}

@use 'styles/colors';

$formGap: 2em;

.signtouch-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.form-input-text {
  position: relative;
  margin-bottom: $formGap;
  display: inline-block;
  flex-grow: none;
  align-self: flex-start;
  width: 16rem;

  label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: colors.$darkgray;

    input {
      display: block;
      font-size: 0.8rem;
      padding: 0.8em;
      border-radius: 0.3em;
      border: colors.$bordergray solid 0.2em;
      margin-top: 0.3em;
      min-width: 20em;
      font-family: 'Montserrat', sans-serif;
      font-weight: normal;
      color: colors.$darkgray;

      &:focus {
        outline: none;
        border: colors.$primary solid 0.2em;
      }
    }

    .price-input {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0.75rem;
        left: 12px;
        top: 12;
        bottom: 0;
        width: 10px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuODM2MDQgMTRIMC4wMzc5NDA0VjEyLjUxMDhIOS44MzYwNFYxNFpNNi4zNzM5OCA4LjA5MDc5SDBWNi42MDE2M0g2LjM3Mzk4VjguMDkwNzlaTTMuMjYyODcgNC4xNDQ5OUwzLjQ3MTU0IDEwLjE4N0MzLjQ3Nzg3IDEwLjc4MTQgMy4zOTI1IDExLjMzNzkgMy4yMTU0NSAxMS44NTY0QzMuMDM4MzkgMTIuMzY4NiAyLjc3MjgxIDEyLjc1NDMgMi40MTg3IDEzLjAxMzZMMC43NjgyOTMgMTIuNTEwOEMwLjk4OTYxMiAxMi40NTM5IDEuMTYzNSAxMi4yOTU4IDEuMjg5OTcgMTIuMDM2NkMxLjQyMjc2IDExLjc3MSAxLjUxNDQ1IDExLjQ2NzUgMS41NjUwNCAxMS4xMjZDMS42MjE5NSAxMC43ODQ2IDEuNjUwNDEgMTAuNDc0NyAxLjY1MDQxIDEwLjE5NjVMMS40NDE3MyA0LjE0NDk5QzEuNDQxNzMgMy4yNzIzNiAxLjYyNTExIDIuNTI5MzYgMS45OTE4NyAxLjkxNTk5QzIuMzU4NjMgMS4yOTYzIDIuODU4MTggMC44MjIwNDIgMy40OTA1MSAwLjQ5MzIyNUM0LjEyOTE4IDAuMTY0NDA4IDQuODUwMDUgMCA1LjY1MzEyIDBDNi40ODE0OCAwIDcuMTg2NTQgMC4xNTQ5MjMgNy43NjgyOSAwLjQ2NDc3QzguMzUwMDUgMC43NzQ2MTYgOC43OTU4NSAxLjIwNzc3IDkuMTA1NjkgMS43NjQyM0M5LjQxNTU0IDIuMzE0MzYgOS41NzA0NiAyLjk1NjE5IDkuNTcwNDYgMy42ODk3SDcuNzQ5MzJDNy43NDkzMiAzLjE1ODU0IDcuNjQxODIgMi43MzE3MSA3LjQyNjgzIDIuNDA5MjFDNy4yMTgxNiAyLjA4NjcyIDYuOTQzMDkgMS44NTI3NiA2LjYwMTYzIDEuNzA3MzJDNi4yNjAxNiAxLjU2MTg4IDUuODkzNDEgMS40ODkxNiA1LjUwMTM2IDEuNDg5MTZDNS4wODQwMSAxLjQ4OTE2IDQuNzA0NjEgMS41OTM1IDQuMzYzMTQgMS44MDIxN0M0LjAyOCAyLjAwNDUyIDMuNzU5MjYgMi4zMDQ4OCAzLjU1NjkxIDIuNzAzMjVDMy4zNjA4OSAzLjA5NTMgMy4yNjI4NyAzLjU3NTg4IDMuMjYyODcgNC4xNDQ5OVoiIGZpbGw9IiMzRjNGM0YiLz4KPC9zdmc+Cg==')
          center / contain no-repeat;
      }
      input {
        padding-left: 2em;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.8rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: colors.$bordergray;
  }
}

.form-input-text-error {
  label {
    input {
      border: colors.$red solid 0.2em;
    }
  }
  span {
    color: colors.$red;
  }
}

.form-textarea {
  margin-bottom: $formGap;

  label {
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: colors.$darkgray;
    margin-bottom: 0.3em;
  }

  .changeBg {
    display: flex;
    float: right;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-top: -0.5rem;
    label {
      margin-left: 5px;
    }
  }

  .textarea {
    border: colors.$bordergray solid 0.2em;
    border-radius: 0.3em;
  }

  &.error {
    .textarea {
      border: colors.$red solid 0.2em;
    }
  }

  .textarea-focus {
    border-radius: 0.3em;
    border: colors.$primary solid 0.2em;
  }

  .textarea-canvas {
    padding: 1em;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.8rem;
    height: 20rem;
    background: colors.$white;
  }
}

.form-single-image.video label::after {
  content: 'Upload Video' !important;
}

.form-single-image {
  label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: colors.$darkgray;
    margin-bottom: 0.3em;
    position: relative;
    margin-bottom: 4em;

    input[type='file'] {
      display: none;
    }

    &::after {
      position: absolute;
      left: 0;
      top: 1.4em;
      content: 'Upload Image';
      background: colors.$primary;
      color: #fff;
      padding: 1em 2em;
      border-radius: 0.2em;
      cursor: pointer;

      &:hover {
        background: darken(colors.$primary, 10%);
      }
    }
  }
}

.images {
  position: relative;
  display: inline-block;
  margin-bottom: 2em;

  .cross {
    position: absolute;
    width: 1.3em;
    height: 1.3em;
    background: colors.$bordergray;
    right: 0.2em;
    top: 0.2em;
    border-radius: 2em;
    cursor: pointer;

    &::after {
      margin: 0.4em 0 0 0.5em;
      content: 'X';
      color: colors.$white;
      font-weight: bold;
      font-size: 0.7em;
      font-family: 'Montserrat', sans-serif;
    }
  }
  .image {
    display: inline-block;
    position: relative;
    margin-right: 0.5em;

    .content {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0.4em;
      height: 7rem !important;
      width: 7rem !important;
    }
  }
}

.form-input-select {
  width: 16rem;
  margin-bottom: $formGap;

  label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: colors.$darkgray;
    position: relative;
    margin-bottom: 0.3em;
  }
}

.form-submit {
  input[type='submit'] {
    border: none;
    background: colors.$primary;
    padding: 1em 2em;
    color: colors.$white;
    border-radius: 0.2em;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;

    &:hover {
      background: darken(colors.$primary, 10%);
    }
  }
}

.form-split {
  text-transform: uppercase;
  font-weight: 800;
  font-family: 'Montserrat', sans-serif;
  &:after {
    width: 30%;
    margin-top: 0.15rem;
    max-width: 4rem;
    height: 0.15rem;
    background: colors.$primary;
    content: '';
    display: block;
  }
}

h2.form-split {
  margin: 0;
  padding: 0.8rem 0;
}

$borderColor: #8d8d8d;

.images {
  .image {
    overflow: hidden;
    position: relative;
    background: #cccccc;
    border-radius: 4px;
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      content: '';
      display: block;
      transform: translate(-50%, -50%);
      border-top: 3px solid transparent;
      border-left: 3px solid $borderColor;
      border-right: 3px solid $borderColor;
      border-bottom: 3px solid $borderColor;
      border-radius: 50%;
      animation: roateInPlace 3s infinite ease-in-out;
    }
    .content {
      width: 7rem;
      height: 7rem;
      overflow-y: hidden !important;
      background-size: cover;
      background-position: center center;
    }
  }
}

@keyframes roateInPlace {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(720deg);
  }
}

.switch-container {
  input[type='checkbox'] {
    display: none;
  }

  input:checked + label.switch {
    background: colors.$green;
  }

  input:checked + label.switch:after {
    top: 0.13rem;
    left: 1.5rem;
  }

  .switch {
    margin: 0.25rem 0;
    cursor: pointer;
    text-indent: -9999px;
    width: 2.8rem;
    height: 1.4rem;
    background: grey;
    display: block;
    border-radius: 1.5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0.13rem;
      left: 0.2rem;
      width: 1.1rem;
      height: 1.1rem;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
      box-shadow: #919191 0 0 5px;
    }
  }
}

.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background: rgba(0, 0, 0, 0.5);
}

.modal-container {
  user-select: none;
  pointer-events: none;
  .form,
  .content {
    pointer-events: all;
  }
}

@use 'styles/preview';

.preview-contact {
  height: 100%;
  color: white;

  h1 {
    margin: calc(preview.$previewVh * 3) 0 preview.$previewVh 0;
    font-size: calc(preview.$previewVh * 3);
  }

  p {
    font-size: calc(preview.$previewVh * 1.5);
  }

  hr {
    width: calc(preview.$previewVh * 10);
    display: block;
    text-align: right;
    margin: calc(preview.$previewVh * 2) 0;
  }

  .contact {
    h4 {
      font-size: calc(preview.$previewVh * 1.5);
    }

    p {
      font-size: calc(preview.$previewVh * 1.3);
    }

    &:first-of-type {
      margin-top: calc(preview.$previewVh * 2);
    }
  }
}

.location-selctor {
  .button {
    &.button-full-width {
      margin-top: -0.2rem;
      border-radius: 0 0 5px 5px;
      width: 100%;
      padding: 0.65rem;
    }
  }

  img {
    border-radius: 5px 5px 0 0;
  }

  label {
    margin-bottom: 0.25rem;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    color: #121212;
  }
}

.map-alignment {
  position: relative;
  z-index: 1000;

  .search {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 101;

    input {
      padding: 0.5rem 1rem;
      min-width: 20rem;
      border-radius: 3px;
      border: none;
    }

    .searchResults {
      max-width: 20rem;
      background: white;
      border-radius: 3px;
      margin-top: 0.5rem;
      .result {
        padding: 0.25rem 1rem;
        border-bottom: 1px solid #e8e8e8;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  .mapboxgl-map {
    height: 88vh;
    min-height: 400px;
    width: 100%;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    color: black;
    z-index: 10000;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    touch-action: none;
    pointer-events: none;

    svg {
      height: 4rem;
      width: 4rem;
    }
  }
}

.select-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;

  .location-controls {
    width: 100%;
    height: 6vh;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: right;
    align-items: center;

    .button {
      display: inline-block;
      width: 8rem;
      padding: 0.75rem 1rem;
      width: 8rem;
    }
  }
}

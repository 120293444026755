@use 'styles/colors';
@import 'styles/breakpoints';

.house-type-grid-title {
  padding-top: 1rem;

  h3 {
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    color: colors.$darkgray;
  }
}

.house-types-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  max-width: 1920px;

  @include media('<=1675px') {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include media('<=1400px') {
    grid-template-columns: 1fr 1fr;
  }

  @include media('<=900px') {
    grid-template-columns: 1fr;
  }
}

@use 'styles/colors';

.sidebar-item-container {
  position: relative;
  overflow: hidden;

  .plus-button {
    z-index: 20;
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0.5em 0 0.5em;
    background: colors.$lightblue;
    width: 16rem;
    top: 0;
    height: 6em;

    img {
      height: 1.6em;
      width: 1.6em;
      padding: 0;
      margin: 0 0.5em;
    }

    p {
      flex: 1;
      display: inline-block;
      font-family: 'Karla', sans-serif;
      font-size: 1.35em;
      font-weight: 700;
      color: colors.$white;
      text-decoration: none;
      margin: 0;
    }
  }
}

.sidebar-item {
  display: flex;
  flex-direction: row;
  box-sizing: border-box !important;
  transition: 0.2s ease;
  position: relative;

  .icon {
    img,
    svg {
      height: 1.6em;
      width: 1.6em;
      padding: 0;
      margin: 2em;
    }
  }

  .label {
    display: flex;
    align-items: center;
    width: 10rem;
    z-index: 1;
    left: 0;

    p {
      display: inline-block;
      font-family: 'Karla', sans-serif;
      font-size: 1.35em;
      font-weight: 700;
      color: colors.$white;
      text-decoration: none;
      margin: 0;
    }
  }

  &:hover {
    background: lighten(colors.$darkgray, 10%);
  }
}

@use 'styles/colors';

.preloader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .loading-bar {
    margin-top: 2rem;
    width: 10rem;
    height: 0.6rem;
    border-radius: 3px;
    background: #b4b4b4;
    position: relative;
    overflow: hidden;

    .loadingChunk {
      height: 100%;
      width: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      background: colors.$primary;
    }
  }
}

@import 'styles/breakpoints';

.transport-plots {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  .button-group {
    display: flex;
    margin-bottom: 0.65rem;
    margin-top: 0.2rem;

    .pending {
      margin-left: -0.25rem;
      margin-top: 0.15rem;
    }
  }

  .top {
    h3 {
      float: left;
    }
    .button {
      margin-top: 0.75rem;
      float: right;
    }
    .pending {
      position: absolute;
      display: inline-block;
    }
  }

  .table {
    margin: 0 0 1rem 0;
    padding: 0 1rem 0 1rem;
    background: white;
    position: relative;
    overflow: hidden;
    height: auto;
    transition: all 0.5s ease-out;

    &.expanded {
      max-height: 100rem;
    }

    &.collapsed {
      max-height: 2.6rem;
      transition: all 0.5s ease-out;
    }

    table {
      padding: 0rem 0;
    }

    .collapse {
      width: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;

      .collapse-container {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        transform: scale(0.8);
        transition: all 0.3s ease-in-out;

        &.collapsed {
          transform: rotate(90deg) scale(0.7);
        }

        span {
          width: 1rem;
          height: 0.15rem;
          background: black;
          display: block;
          position: absolute;
          border-radius: 3px;

          &:nth-child(1) {
            transform: translate(-9%, 490%) rotate(45deg);
          }

          &:nth-child(2) {
            transform: translate(57%, 490%) rotate(-45deg);
          }
        }
      }
    }
  }

  .method {
    .pending {
      position: absolute;
      display: inline-block;
    }

    align-items: center;
    display: flex;
    margin: 0.1rem 0;

    &:first-of-type {
      margin-top: -0.5rem;
    }

    &:last-of-type {
      margin-bottom: 0.5rem;
    }

    p {
      margin-left: 0.5rem;
    }

    p,
    .switch-container {
      display: inline-block;
    }
  }
}

.transport-links {
  .pending-span {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-top: 3px solid #868686;
    border-left: 3px solid #868686;
    border-right: 3px solid #868686;
    border-bottom: 3px solid white;
    border-radius: 1.5rem;
    content: '';
    margin-left: 0.5rem;
    animation: rotate360 2s infinite linear;
  }

  .no-points {
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 2rem;
  }

  .modal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    .header {
      display: block;
      justify-content: space-between;
      padding: 0 0.5rem;
      margin: 0;

      p {
        float: right;
      }

      h2 {
        font-weight: 800;
        font-family: 'Karla', sans-serif;
        float: left;
      }
    }

    .body {
      background: white;
      width: 80vw;
      max-width: 600px;
      padding: 0.5rem 1rem;
      overflow-y: hidden !important;

      .form {
        transform: none;
        position: unset;
        width: auto;

        .form-textarea {
          .textarea-canvas {
            height: 9rem;
          }
        }

        .form-input-select,
        .form-input-text {
          width: 100%;
        }
      }
    }
  }
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@use 'styles/colors' as *;

.toasts {
  z-index: 100000000;
  position: absolute;
  max-width: 460px;
  bottom: 15px;
  right: 15px;
  pointer-events: none;

  .toast {
    position: relative;
    float: right;
    display: inline-flex;
    flex-direction: row;
    background: #fff;
    min-width: 270px;
    max-width: 460px;
    width: auto;
    border-radius: 4px;
    overflow: hidden;
    pointer-events: auto;
    margin: 0.3rem 0;

    .toast-icon {
      padding: 1rem 0 1rem 1rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .toast-message {
      flex: 1;
      padding: 1rem;
      font-family: 'Karla', sans-serif;
      display: flex;
      align-self: center;

      p {
        font-size: 0.8rem;
        margin: 0;
        color: #fff;
        font-weight: 600;
      }
    }

    .close-button-container {
      padding: 0.5rem;

      .close-button {
        border-radius: 4px;
        display: inline-block;

        &:hover {
          background: rgba(#000, 0.2);
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
          stroke: #fff !important;
        }
      }
    }

    &.pending {
      background: $primary;
    }
    &.error {
      background: $red;
    }

    &.notification {
      background: #19ce3c;
    }
  }
}

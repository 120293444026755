@use 'styles/colors';
@import 'styles/breakpoints.scss';

.static-pages {
  p {
    font-size: 0.9rem;
    color: rgb(107, 107, 107);
  }
}

.static-pages-list {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  .static-page-link-container {
    .static-page-link {
      padding: 1rem;
      background: colors.$white;
      cursor: pointer;
      border-radius: 0.2rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        height: 1.3rem;
        width: 1.3rem;
        margin: 0 1rem 0 0;
      }

      p {
        font-size: 0.8rem;
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        color: colors.$darkgray;
        text-transform: capitalize;
        font-weight: 500;
        font-size: 0.8rem;
        color: colors.$darkgray;
      }
    }
  }
}

@use 'styles/preview';

.preview-home {
  width: 100%;
  height: 100%;

  .preview-home-header {
    p {
      padding: calc(preview.$previewPadding * 2);
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: calc(preview.$previewVh * 2);
      font-family: 'Monsterrat', sans-serif;
    }
  }
}

.preview-design-advice {
  color: white;
  margin-bottom: 1rem;
  padding: 1rem 0;

  h4 {
    margin: 1rem 0;
    &:after {
      display: block;
      content: '';
      background: white;
      width: 30%;
      height: 3px;
      margin-top: 0.5rem;
    }
  }
}

.border-t {
  margin-top: calc(preview.$previewVh * 2);
  padding-top: calc(preview.$previewVh * 1);
  border-top: 1px solid black;
}

.border-b {
  padding-bottom: calc(preview.$previewVh * 1);
  margin-bottom: 1.25rem;
  border-bottom: 1px solid black;
}

.white {
  color: white;
}

.bold-font {
  font-weight: bolder;
}

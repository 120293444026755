@use 'styles/preview';

.preview-carousel {
}

.preview-carousel-slide {
  height: calc(preview.$previewVh * 30);
  width: calc(preview.$previewVh * 53) !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.splide__arrow {
  align-items: center;
  justify-content: center;

  svg {
    margin: 0 !important;
  }
}

.options-selector {
  .options-selector-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0.3em 0;

    .options-selector-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 0.9rem;
        color: #121212;
        margin: 0;
      }

      p {
        margin: 0 0 0 1rem;
      }
    }

    .options-selector-all {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      p {
        margin: 0;
      }
    }
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 0 2em 0;
}

.preview-promotions-index {
  display: block;

  .header {
    background: #1b1b1d;
    margin: 0;
    width: 100%;
    padding: 3rem 2rem;
    h1 {
      display: block;
      padding: 0;
      margin: 0;
    }
  }

  .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 50%;
      background: snow;
      padding: 1.5rem 1.25rem;
      &:nth-child(4n + 2),
      &:nth-child(4n + 3) {
        background: #dedede;
      }
      h3 {
        font-weight: normal;
        font-size: 1.2em;
        margin: 1rem 0 0 0;
      }
      p {
        margin: 0.5rem 0 0 0;
      }
      .img {
        width: 100%;
        height: 13em;
        background-size: cover;
        background-position: center;
        content: '';
      }
    }
  }
}

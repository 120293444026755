@use 'styles/preview';

.preview-menu {
  z-index: 2;
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(preview.$previewVh * 13.5);
  height: calc(preview.$previewVh * 85.5);
  background: #fff;

  .preview-menu-heading {
    padding: calc(preview.$previewPadding * 2);

    p {
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: calc(preview.$previewVh * 2);
    }
  }
}

.preview-menu-button {
  top: calc(preview.$previewVh * 11);
  right: calc(preview.$previewPadding * 2);
  position: absolute;
  display: inline-block;
  z-index: 10;
  height: calc(preview.$previewVh * 7);
  width: calc(preview.$previewVh * 7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(preview.$previewVh * 100);

  .preview-menu-text {
    position: absolute;
    color: #fff;
    top: calc(preview.$previewVh * 6);
  }

  .cross {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 60%;
      height: auto;
      stroke: #fff;
      fill: #fff;
    }
  }
}

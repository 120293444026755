@use 'styles/preview';

.preview-menu-item {
  background: #fff;
  // padding: preview.$previewPadding calc(preview.$previewPadding * 2);
  display: flex;
  align-items: center;

  svg,
  img {
    height: calc(preview.$previewVh * 2.6);
    width: calc(preview.$previewVh * 2.6);
    margin: preview.$previewPadding calc(preview.$previewPadding * 2);
    margin-right: preview.$previewPadding;
  }

  p {
    margin: 0;
    font-size: calc(preview.$previewVh * 1.7);
    flex: 1;
  }

  .signtouch-arrow {
    height: calc(preview.$previewVh * 3);
    width: calc(preview.$previewVh * 3);

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: calc(preview.$previewVh * 100);
    margin-right: calc(preview.$previewPadding * 2);

    svg {
      height: 50%;
      width: 50%;
      padding: 0;
      margin: 0;
    }
  }

  &:nth-child(even) {
    background: #f1f1f1;
  }
}

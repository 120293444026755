@use 'styles/preview';

.preview-promotions-index {
  .heading {
    background: #1b1b1d;
    margin: 0;
    width: 100%;
    padding: calc(preview.$previewVh * 4) calc(preview.$previewVh * 5);

    h3 {
      font-size: calc(preview.$previewVh * 2.2);
      text-transform: none;
      display: block;
      padding: 0;
      margin: 0;
      color: white;
      font-weight: 700;
    }
  }

  .preview-promotions-split {
    display: grid;
    grid-template-columns: 6.5fr 5fr;

    .preview-promotions-content {
      padding: 0 0 0 calc(preview.$previewVh * 2.5);
    }

    .preview-promotions-side-cards {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: calc(preview.$previewVh * 0.5);
      margin: calc(preview.$previewVh * 3) 0;
    }
  }
}

@use 'styles/colors';
@import 'styles/breakpoints';

.options-overview {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  ul {
    color: colors.$darkgray;
  }

  .tree {
    margin: 0 0 2em 0;
    background: colors.$white;
    border-radius: 0.3rem;
  }
}

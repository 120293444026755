@use 'styles/preview';

.preview-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .preview-home-header {
    p {
      padding: calc(preview.$previewPadding * 2);
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: calc(preview.$previewVh * 2);
      font-family: 'Montserrat', sans-serif;
    }
  }

  .preview-home-video {
    display: flex;
    flex-direction: row;

    video {
      flex: 1;
      max-width: 100%;
    }
  }

  .preview-start-button-conatiner {
    width: calc(100% - (preview.$previewVh * 4));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 38.4vh;

    .preview-start-button-layer-1 {
      display: inline-block;
      padding: calc(preview.$previewPadding / 2);
      background: rgba(#333, 0.5);
      border-radius: calc(preview.$previewVh * 100);
      transform-origin: center !important;

      .preview-start-button-layer-2 {
        padding: calc(preview.$previewPadding / 2);
        background: rgba(#333, 0.5);
        border-radius: calc(preview.$previewVh * 100);

        .preview-start-button {
          padding: preview.$previewPadding;
          border-radius: calc(preview.$previewVh * 100);
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(preview.$previewVh * 10);
          width: calc(preview.$previewVh * 10);

          p {
            text-transform: uppercase;
            font-size: calc(preview.$previewVh * 2);
            font-weight: bold;
          }
        }
      }
    }

    p {
      font-size: calc(preview.$previewVh * 1.8);
      color: #fff;
    }
  }

  .preview-home-images {
    width: calc(65% - preview.$previewVh * 4);
    margin: calc(preview.$previewVh * 18) auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    img {
      height: calc(preview.$previewVh * 10);
      width: auto;
      padding: calc(preview.$previewVh / 2);
      background: #fff;

      &:nth-child(odd) {
        transform: rotate(-20deg);
      }
      &:nth-child(even) {
        transform: rotate(20deg);
      }
    }
  }
}

@use 'styles/colors';

.fetching-developments {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 5rem;
    width: auto;
  }

  p {
    font-size: 0.9rem;
    color: rgb(177, 177, 177);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}

.parent-conatiner {
  background: colors.$white;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  .parent {
    .heading {
      display: flex;
      flex-direction: row;
      color: colors.$darkgray;
      padding: 2rem 2rem 2rem 2rem;
      border-bottom: 1px solid colors.$bordergray;

      .title {
        h3 {
          margin: 0;
          text-transform: capitalize;
          font-weight: 500;
          font-family: 'Montserrat', sans-serif;
          font-size: 1.2rem;
        }

        P {
          margin: 0.4rem 0;
          font-family: 'Karla', sans-serif;
          font-size: 1rem;
          color: colors.$bordergray;
        }
      }

      .buttons {
        flex: 1;
        display: flex;
        justify-content: right;
        align-items: center;

        .button {
          background: colors.$primary;
          padding: 1rem 2rem;
          display: inline-block;

          p {
            margin: 0;
          }
        }
      }
    }
  }

  .children {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1200px) {
      display: block;
    }

    .child-container {
      padding: 1rem;

      .child {
        display: flex;
        flex-direction: row;
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 15%);
        border-radius: 0.5rem;

        .thumbnail {
          display: block;
          background-repeat: none;
          background-size: cover;
          width: 7rem;
          height: 7rem;
        }

        .heading {
          padding: 0;
          flex: 1;
          display: flex;
          border: none;
          color: colors.$darkgray;

          .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h3 {
              font-size: 1rem;
            }
            p {
              font-size: 0.8rem;
              color: colors.$bordergray;
              margin-bottom: 0;
            }
            p.city {
              color: colors.$primary;
            }
          }
        }
      }
    }

    p {
      margin: 0;
    }
  }
}

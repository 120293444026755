@use 'styles/colors';

body {
  overflow-x: hidden;
  font-family: 'Karla', sans-serif;
}

.menu-button {
  position: absolute;
  top: 0;
  left: 0;
  background: colors.$darkgray;
  width: 3em;
  padding: 0.25em;
  justify-content: center;
  align-items: center;
  height: 3.1em;
  z-index: 10000;
  display: none;
  box-sizing: border-box;
  transition: all 0.3s ease;
  transform: translate(0em, 0);
  @media screen and (max-width: 1200px) {
    display: flex;
  }
  svg {
    margin: 0;
  }
  &.open {
    transform: translate(19.2em, 0);
  }
}

.main-layout {
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  max-height: 100vw;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;

  .page {
    flex: 1;
    // display: flex;
    flex-direction: column;
    max-height: 100vh;

    .development-banner {
      display: flex;
      gap: 8px;
      background: colors.$darkgray;
      justify-content: right;
      align-items: center;
      z-index: 10;
      padding: 8px;
    }

    .content {
      flex: 1;
      position: relative;
      overflow-y: scroll;
      height: calc(100% - 60px);
    }
  }
}

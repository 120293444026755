@use 'styles/colors';

.house-card {
  position: relative;
  display: inline-flex;
  background: colors.$white;
  border: 0.1em colors.$bordergray solid;
  border-radius: 0.5em;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;

  a {
    flex: 1;
    display: inline-flex;
  }

  .house-card-image {
    height: 7.5rem;
    width: 7.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .house-card-about {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    margin-left: 1em;

    h3 {
      text-transform: uppercase;
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
      font-size: 1.2em;
      margin: 0;
      color: colors.$darkgray;
    }

    .house-card-info {
      display: flex;
      flex-direction: row;
      margin-top: 0.5em;

      svg {
        height: 1.2rem;
        width: auto;
        fill: #7e7e7e;
      }

      .house-name-bedrooms {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          display: inline-block;
          margin: 0 0 0 0.5em;
          font-family: 'Montserrat', sans-serif;
          font-size: 0.9em;
          color: colors.$bordergray;
          font-weight: bold;
        }
      }
    }
  }

  .availability {
    position: absolute;
    top: 1em;
    right: 1em;
    color: colors.$primary;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
  }

  .actions {
    position: absolute;
    bottom: 1em;
    right: 1em;
    display: flex;
    flex-direction: row;
    z-index: 9;

    svg {
      width: 1.3rem;
      height: 1.3rem;
      margin-left: 1em;
      cursor: pointer;

      &.delete {
        &:hover {
          fill: #ff2233;
        }
      }
      &.copy {
        &:hover {
          fill: colors.$primary;
        }
      }
    }
  }
}

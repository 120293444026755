@import 'styles/breakpoints';

.promotions-and-sales-methods {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  .form-content {
    max-width: 760px;
  }
}

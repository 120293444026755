@use 'styles/colors';

.logout {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 1em;
  &:hover {
    background: lighten(colors.$darkgray, 10%);
  }

  p {
    color: colors.$white;
    margin: 0 1em 0 0;
    font-size: 0.9rem;
    font-family: 'Karla', sans-serif;
  }
  img {
    height: 1.4em;
    width: 1.4em;
  }
}

@use 'styles/preview';
@import 'styles/breakpoints';

.page-select {
  @include media('<=1500px') {
    display: none;
  }
}

.preview-layout-container {
  background: #121212;
  padding: calc(preview.$previewVh * 2);
  display: inline-block;
  overflow: hidden;

  .page-select {
    position: relative;
    top: 1rem;
  }

  @include media('<=1500px') {
    display: none;
  }

  .preview-layout {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: calc((80vh / 16) * 9);
    position: relative;
    overflow: hidden;

    .preview-content {
      overflow: hidden;

      &::-webkit-scrollbar {
        display: none;
      }

      div.animate {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      flex: 1;
      background: #ffffff;
      padding: 0 preview.$previewPadding;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
}

@use 'styles/preview';

.preview-welcome {
  width: 100%;
  height: 100%;

  .preview-welcome-header {
    p {
      padding: calc(preview.$previewPadding * 2);
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: calc(preview.$previewVh * 2);
      font-family: 'Monsterrat', sans-serif;
    }
  }

  .preview-welcome-video {
    video {
      width: calc(
        ((((100 * preview.$previewVh) / 16)) * 9) - (preview.$previewVh * 4)
      );
    }
  }
}

.flex-grid {
  display: flex;
  margin: 0 calc(preview.$previewVh * 2);
  justify-content: space-between;

  .col-* {
    padding: calc(preview.$previewVh * 1) 0rem;
  }

  .col-2-3 {
    width: 63%;
  }

  .col-1-3 {
    width: 30%;
  }

  .col-1-4 {
    width: 25%;
  }

  .col-3-4 {
    width: 75%;
  }
}

.border-t {
  margin-top: calc(preview.$previewVh * 2);
  padding-top: calc(preview.$previewVh * 1);
  border-top: 1px solid black;
}

.border-b {
  padding-bottom: calc(preview.$previewVh * 1);
  margin-bottom: calc(preview.$previewVh * 1.5);
  border-bottom: 1px solid black;
}

.white {
  color: white;
}

.bold-font {
  font-weight: bolder;
}

.preview-welcome-left {
  p {
    font-size: calc(preview.$previewVh * 1.2);
  }
}

.preview-welcome-right {
  p {
    font-size: calc(preview.$previewVh * 1.5);
    margin: calc(preview.$previewVh * 1.2) 0;
  }
}

@use 'styles/colors';

.dimensions-editor {
  margin-top: 2rem;

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 0.9rem;
    color: colors.$darkgray;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    color: colors.$lightgray;
    font-family: 'Karla', sans-serif;
    font-weight: 500;
    margin: 0.4rem 0 0.4rem 0;
  }

  .editor {
    form {
      .editor-form {
        display: flex;
        flex-direction: column;
      }

      label {
        text-transform: capitalize;
        font-size: 0.8rem;
        color: colors.$darkgray;
        font-family: 'Montserrat', sans-serif;
      }

      .input {
        flex: 1;
        width: 16rem;

        input,
        select {
          display: block;
        }
        input[type='text'] {
          font-size: 0.8rem;
          padding: 0.8em;
          border-radius: 0.3em;
          border: colors.$bordergray solid 0.2em;
          min-width: 20em;
          font-family: 'Montserrat', sans-serif;
          font-weight: normal;
          color: colors.$darkgray;
          margin-bottom: 0.5rem;

          &:focus {
            outline: none;
            border: colors.$primary solid 0.2em;
          }
        }
        input[type='number'] {
          font-size: 0.8rem;
          padding: 0.8em;
          border-radius: 0.3em;
          border: colors.$bordergray solid 0.2em;
          min-width: 20em;
          font-family: 'Montserrat', sans-serif;
          font-weight: normal;
          color: colors.$darkgray;
          margin-bottom: 0.5rem;

          &:focus {
            outline: none;
            border: colors.$primary solid 0.2em;
          }
        }
        select {
          font-size: 0.8rem;
          padding: 0.8em;
          border-radius: 0.3em;
          border: colors.$bordergray solid 0.2em;
          min-width: 20em;
          font-family: 'Montserrat', sans-serif;
          font-weight: normal;
          color: colors.$darkgray;
          margin: 0 0 0.5rem 0;

          &:focus {
            outline: none;
            border: colors.$primary solid 0.2em;
          }
        }
      }

      input[type='submit'] {
        border: none;
        background: colors.$primary;
        padding: 1em 2em;
        color: colors.$white;
        border-radius: 0.2em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 0.9rem;
        cursor: pointer;

        &:hover {
          background: darken(colors.$primary, 10%);
        }
      }
    }
  }

  table {
    margin-top: 0.5rem;

    td {
      .icons {
        display: flex;
        justify-content: flex-end;

        .shared-icon {
          svg {
            height: 1rem;
            width: 1rem;
            margin: 0.5rem;
          }
        }
      }
    }
  }
}

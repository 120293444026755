@use 'styles/colors';
@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-size: 12px;
  touch-action: none;
  background: #f0f0f0;
}
::selection {
  background: colors.$primary; /* WebKit/Blink Browsers */
  color: colors.$white;
}

a {
  text-decoration: none;
}

.header,
.body {
  margin: 0 2rem;
}

.page-padding {
  padding: 4em 4em 4em 4em;
  position: relative;
  height: 100%;
  width: 100%;
}

.rdw-block-wrapper ul {
  color: #333;
}

.rdw-colorpicker-modal {
  width: auto;
  border-radius: 3px;
  height: auto;
}

.rdw-colorpicker-modal-options {
  overflow: unset;
  width: 100%;
  min-width: 224px;
  height: 100%;
}

body {
  overflow: hidden;
}

.App {
  overflow-x: scroll;
  max-width: 100vw;
}

.heading-with-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

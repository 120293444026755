@use 'styles/colors';

.shared-button {
  display: inline-block;
  padding: 1rem 2rem;
  font-size: 1.15em;
  border-radius: 4px;
  background: colors.$primary;
  color: colors.$white;
  font-weight: 900;
  font-family: 'Montseratt', sans-serif;
  text-transform: uppercase;
  cursor: pointer;

  p {
    margin: 0;
  }
}

@use 'styles/colors' as *;

.button {
  padding: 1rem 2rem;
  font-size: 1.15em;
  border-radius: 4px;
  background: $bordergray;
  color: $white;
  font-weight: 900;
  font-family: 'Montseratt', sans-serif;
  text-transform: uppercase;

  &.button-primary {
    background: $primary;
  }

  &.button-red {
    background: $red;
  }

  &.button-table {
    font-weight: 900;
    font-size: 0.6rem;
    padding: 0.25rem 1rem;
    margin-right: 0.5rem;
    &:first-of-type {
      margin-left: 0;
    }
  }
}

@use 'styles/colors';

.side-bar {
  background: colors.$darkgray;
  color: colors.$white;
  min-width: 16rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  z-index: 20;

  &.always .sidebar-container {
    button {
      display: none;
    }
  }

  .sidebar-container {
    position: relative;

    button {
      display: none;
    }
  }

  img {
    width: 15rem;
    height: auto;
    padding: 2rem;
  }

  span {
    align-self: center;
    margin-bottom: 7px;
    color: #818181;
  }
}

.back-to-developments-container {
  margin-top: auto;
  padding: 1rem;

  .back-to-developments {
    padding: 2em;
    text-align: center;
    border-radius: 0.5rem;

    &:hover {
      background: lighten(colors.$darkgray, 10%);
    }

    p {
      font-weight: 500;
      font-family: 'Karla', sans-serif;
      color: colors.$white;
      margin: 0;
      font-size: 1rem;
    }
  }
}

// iPad
@media screen and (max-width: 1200px) {
  .side-bar {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    transform: translate(-100%, 0);
    transition: all 0.3s ease;

    .sidebar-container {
      button {
        position: absolute;
        right: -2rem;
        top: 0;
        background: black;
        display: block;
      }
    }

    &.close {
      transform: translate(0%, 0);
    }

    &.always {
      position: revert;
      transform: translate(0%, 0);
    }
  }
}

.sidebar-separator {
  display: inline-block;
  width: calc(100% - 3rem);
  height: 2px;
  padding: 0;
  margin: 0 !important;
  content: '';
  background: #3f3f3f;
}

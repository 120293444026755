@use 'styles/colors';

.card-container {
  padding: 1rem;

  .card {
    border-radius: 0.5rem;
    background: colors.$white;

    .heading {
      padding: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h3 {
        font-family: 'Montserrat', sans-serif;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 1.2rem;
        margin: 0;
      }

      svg,
      img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }

    .quantity {
      padding: 0 2rem 2rem 2rem;

      p {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 2rem;
        font-weight: 400;
      }
    }

    .description {
      padding: 0 2rem 2rem 2rem;

      p {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        font-size: 0.8rem;
        font-weight: 500;
        color: rgb(107, 107, 107);
        text-transform: capitalize;
      }
    }
  }
}

@use 'styles/colors';

.gallery-editor {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1500px) {
    display: flex;
    flex-direction: column-reverse;
  }

  .images-array {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image-array-content {
      display: flex;
      min-height: 40vh;
      text-align: center;
      justify-content: center;
      align-items: center;

      p {
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
      }

      .gallery-editor-images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .gallery-image-container {
          padding: 0.2rem;
          position: relative;

          .gallery-image {
            padding: 0.2rem;
            background: #fff;
            border-radius: 0.5rem;
            display: flex;
            flex-direction: row;
            cursor: pointer;

            .gallery-image-content {
              .gallery-image-content-clip {
                height: 5rem;
                width: 8rem;
                padding: 0;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 0.3rem;
              }
              img {
                object-fit: cover;
                width: 10rem;
                height: 100%;
                border-radius: 0.5rem;
                padding: 0;
              }
            }

            .gallery-image-controls {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .gallery-image-drag {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.8rem 0.5rem;
                border-radius: 0.5rem;
                margin: 0.2rem 0 0 0.2rem;
                height: 100%;
                transition: 0.2s ease;

                &:hover {
                  background: darken(#f1f1f1, 10%);
                  cursor: grab;
                }
                svg {
                }
              }
              .delete-image {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.5rem;
                cursor: pointer;
                margin: 0 0 0 0.2rem;
                padding: 0.5rem 0.5rem;
                transition: 0.2s ease;

                &:hover {
                  background: red;

                  svg {
                    fill: colors.$white;
                  }
                }

                svg {
                  fill: colors.$darkgray;
                  width: 1rem;
                  height: 1rem;
                  padding: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .image-viewer {
    background: colors.$white;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      user-select: none;
      height: 20rem;
      width: auto;
      padding: 0.2rem;
    }
  }
}

.image-array-delete-image {
  background: red;
  color: white;
  p {
    padding: 5rem 0;
    margin: 0;
  }
}

@use 'styles/preview';

.preview-side-card {
  position: relative;

  .preview-side-card-image {
    height: calc(preview.$previewVh * 11);
    width: calc(preview.$previewVh * 19.5);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: calc(preview.$previewVh * 3);

    .preview-side-card-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.2);
      height: 100%;
      width: 100%;
    }

    p {
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      font-size: calc(preview.$previewVh * 1.5);
      text-align: center;
      z-index: 1;
    }
  }
}

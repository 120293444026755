@use 'styles/colors';
@import 'styles/breakpoints';

.development-selector-current {
  padding: 10px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 0.9rem;
    font-family: 'Karla', sans-serif;
  }

  &:hover {
    background: lighten(colors.$darkgray, 10%);
  }
}

.development-selector-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1002;

  .development-selector {
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    background: colors.$darkgray;
    height: 100vh;
    overflow-y: scroll;
    perspective: 1px;
    display: flex;
    flex-direction: column;
    color: #fff;
    width: 25rem;

    .development-selector-close {
      display: flex;
      flex-direction: row-reverse;

      svg {
        height: 2rem;
        width: 2rem;
        margin: 0px 0px 8px 8px;
        cursor: pointer;
      }
    }

    .development-selector-search {
      margin: 0 0 8px 0;

      input {
        width: 100%;
        padding: 12px;
        border: none;
        border-radius: 4px;
        font-size: 0.9rem;
        font-family: 'Karla', sans-serif;
      }
    }

    .development-selector-instructions {
      p {
        font-size: 0.8rem;
        color: lighten(colors.$darkgray, 30%);
        margin: 0 0 8px 0;
      }
    }

    .development-selector-results {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .parent-result {
        border-radius: 4px;
        overflow: hidden;
        background: lighten(colors.$darkgray, 20%);

        .parent-heading {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: lighten(colors.$darkgray, 10%);
          padding: 8px;
          font-family: 'Karla', sans-serif;

          h3 {
            margin: 0;
            font-size: 0.9rem;
            font-family: 'Karla', sans-serif;
          }

          a,
          p {
            margin: 0;
            color: #fff;
            padding: 4px;
            border-radius: 4px;
            font-size: 0.8rem;
          }
        }
      }

      .parent-results {
        display: flex;
        flex-direction: column;
        // background: lighten(colors.$darkgray, 20%);
        padding: 8px;
        gap: 2px;

        .child-result {
          padding: 8px;
          border-radius: 4px;
          // background: lighten(colors.$darkgray, 30%);
          cursor: pointer;

          p {
            margin: 0;
            font-size: 0.8rem;
            font-family: 'Karla', sans-serif;
          }

          &:hover {
            background: lighten(colors.$darkgray, 40%);
          }
        }
      }
    }

    @include media('<=tablet') {
      width: 100vw;
    }

    .development-selector-text-input {
      width: 20rem;

      @include media('<=tablet') {
        width: 100%;
      }
    }
  }

  .development-selector-dismissal {
    background: rgba(#000, 80%);
    flex: 1;
    height: 100vh;
    cursor: pointer;
    z-index: 1001;

    @include media('<=tablet') {
      display: none;
    }
  }
}

@use 'styles/colors';
@import 'styles/breakpoints';

.parent-properties {
  display: grid;
}

.parent-properties-forms {
  margin-bottom: 1rem;
}

.metadata-toggle-switch {
  margin-bottom: 20px;

  input[type='checkbox'] {
    display: none;
  }

  input:checked + label.switch {
    background: colors.$green;
  }

  input:checked + label.switch:after {
    top: 0.13rem;
    left: 1.5rem;
  }

  .switch {
    margin: 0.25rem 0;
    cursor: pointer;
    text-indent: -9999px;
    width: 2.8rem;
    height: 1.4rem;
    background: grey;
    display: block;
    border-radius: 1.5rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0.13rem;
      left: 0.2rem;
      width: 1.1rem;
      height: 1.1rem;
      background: #fff;
      border-radius: 90px;
      transition: 0.3s;
      box-shadow: #919191 0 0 5px;
    }
  }
}

.option-selector-category {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  overflow: hidden;

  .option-selector-category-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem 0 0;
    cursor: pointer;

    .chevron {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    h1 {
      margin: 0 0 0 0.2rem;
      font-size: 1rem;
    }
  }
  .option-selector-category-types {
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }
}

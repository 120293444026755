.option-selector-type {
  background: #f1f1f1;
  padding: 1rem;
  border-radius: 8px;

  .option-selector-type-header {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

    .chevron {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 1rem;
        width: 1rem;
      }
    }

    h3 {
      margin: 0 0 0 0.2rem;
      margin: 0;
    }
  }
}

.option-selector-type-options {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0;
  gap: 0.2rem;
}

.other-contacts {
  margin: 0.5rem 0;
  padding-bottom: 20px;

  table.table {
    margin: 0;
    border-radius: 5px;
    thead {
      tr {
        td {
          padding: 1rem;
        }
      }
    }

    td {
      input {
        width: 100%;
        margin: 0rem;
        border-radius: 4px;
        padding: 0.2rem 1rem;
        border: 0.12rem solid #333;
      }
    }
  }
}

@use 'styles/colors';

.tree-item {
  padding: 0.5rem 1rem 0.5rem 1rem;
  background: colors.$white;
  user-select: none;
  margin-bottom: 0.5rem;
  transition: 0.2s ease;

  .header {
    display: flex;
    padding: 0;
    margin: 0;

    a {
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .shared-icon {
    &:hover {
      svg.plus {
        fill: colors.$primary;
      }
    }
  }

  p {
    margin: 0;
  }

  .title {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
      flex: 1;
      color: colors.$darkgray;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 0.8rem;
      text-transform: capitalize;
    }

    svg {
      height: 0.8rem;
      width: auto;
      transform-origin: center !important;
      margin: 0.4rem;
    }
  }

  .icons {
    display: inline-flex;
    svg {
      height: 0.8rem;
      margin: 0.4rem;
      width: auto;
    }
  }

  .tree-item {
    background: colors.$offwhite;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    transition: ease 0.2s;

    .shared-icon {
      &:hover {
        svg.plus {
          fill: colors.$primary;
        }
      }
    }

    .tree-item {
      border-radius: 0.3rem;
      padding: 0.1rem 1rem 0.1rem 0.5rem;

      p {
        color: lighten(colors.$darkgray, 20%);
      }

      .shared-icon {
        &:hover {
          svg.delete {
            fill: colors.$red;
          }
        }
      }

      &:hover {
        background: darken(colors.$white, 10%);

        .shared-icon {
          svg.edit {
            fill: colors.$primary;
          }
        }
      }
    }
  }
}

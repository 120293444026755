@use 'styles/colors' as *;
@import 'styles/breakpoints';

.plots-overview {
  .heading {
    // padding: 4em;
  }

  @include media('>=800px') {
    table {
      // padding: 0 4em;
    }
  }

  table {
    th {
      padding: 0.65rem 0.5rem 0.4rem 1rem;
    }
  }
}

table,
thead,
td,
tr {
  border: none !important;
}

table {
  border-radius: 3px;
  display: table;
  width: 100%;
  border: none;
  font-family: 'Karla', sans-serif;
  overflow: hidden;
  margin-bottom: 2rem;

  th {
    padding: 1rem;
    text-transform: uppercase;
    background: $primary;
    text-align: left;
    color: white;
    vertical-align: center;
    font-weight: bold;
    position: relative;
    font-size: 0.8rem;

    p {
      display: inline-block;
      padding: 0;
      margin: 0.8em 0;
    }

    span {
      height: 2.5em;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 0em;
      svg {
        display: inline-block;
        transition: all 0.3s;
      }
      &.active p.active svg {
        fill: #ffffff;
      }
      p {
        margin: 0;
        padding: 0;
        svg {
          fill: darken($primary, 10%);
          height: 0.8rem;
          width: 1.8em;
        }
        &:nth-child(1) {
          transform: rotate(180deg);
        }
      }
    }
  }

  tbody {
    tr {
      background: #f1f1f1;

      &:nth-child(2n) {
        background: darken(#efefef, 10%);
      }
    }

    td {
      border: none;
      padding: 0.75rem 1rem;

      &.right {
        text-align: right;
      }

      &.center {
        text-align: center;
      }

      span {
        background: gray;
        display: inline-block;
        padding: 0.25rem 0.5rem;
        color: white;
        font-weight: bolder;
        border-radius: 3px;

        &.availability-span {
          opacity: 0.7;
          background: rgba(#1f1f1f, 0.8);
        }
        &.available {
          background: $primary;
        }
        &.reserved {
          background: #1b1b1d;
        }

        &.future {
          background: greenyellow;
        }

        &.sold {
          background: #0cb1b1;
        }
      }
    }
  }

  tr {
    display: table-row;
  }

  td {
    padding: 2rem;
    display: table-cell;
  }
}

.table-sort-container {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.table-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;

  .table-button {
    svg {
      height: 1.5rem;
      width: 1.6rem;
      fill: #696969;

      &:hover {
        fill: darken(#696969, 20%);
      }

      &.delete {
        &:hover {
          fill: crimson;
        }
      }
    }
  }
}

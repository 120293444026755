@use 'styles/colors';

.child-container {
  .child {
    display: flex;
    flex-direction: row;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 15%);
    border-radius: 0.5rem;

    .thumbnail {
      display: block;
      background-repeat: none;
      background-size: cover;
      width: 7rem;
      height: 7rem;
    }

    .child-heading {
      padding: 0;
      flex: 1;
      display: flex;
      border: none;
      color: colors.$darkgray;
      align-items: center;

      .title {
        h3 {
          margin: 0;
          text-transform: capitalize;
          font-weight: 500;
          font-family: 'Montserrat', sans-serif;
          font-size: 1rem;
        }

        p {
          margin: 0.4rem 0;
          font-family: 'Karla', sans-serif;
          font-size: 0.8rem;
          color: colors.$bordergray;
        }

        p.city {
          color: colors.$primary;
        }
      }
    }
  }
}

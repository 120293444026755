$darkgray: #121212;
$lightgray: #333;
$bordergray: #959595;
$white: #fff;
$offwhite: #f1f1f1;
$lightblue: #0cc2eb;
$primary: #27a1fc;
$red: #ff2233;
$green: #0dd635;

:export {
  darkgray: $darkgray;
  lightgray: $lightgray;
  bordergray: $bordergray;
  white: $white;
  offwhite: $offwhite;
  lightblue: $lightblue;
  primary: $primary;
}

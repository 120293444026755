@import 'styles/breakpoints';

.promotions-and-sales-methods {
  .promotions-list {
    width: 50%;

    @include media('<=1500px') {
      width: 100%;
    }

    .promotion {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: snow;
      border-radius: 3px;
      padding: 0 1rem;
      max-height: 2.6rem;
      margin-bottom: 10px;
      overflow: hidden;

      .promotion-details {
        h4 {
          display: block;
          padding: 0;
        }
      }

      .promotion-links {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        svg {
          height: 1.3rem;
          width: 1.3rem;
          fill: rgb(90, 90, 90);

          &:hover {
            fill: darken(rgb(90, 90, 90), 10%);
          }
        }

        .delete-promotion {
          svg {
            &:hover {
              fill: crimson;
            }
          }
        }
      }
    }
  }
}

.parent-promotions {
  opacity: 0.7;
}

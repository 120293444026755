@use 'styles/preview';

.preview-footer {
  background: #ffffff;
  padding: preview.$previewPadding;

  .preview-footer-grid {
    display: flex;
    font-family: 'Montserrat', sans-serif;
    gap: preview.$previewVh;

    .preview-footer-card {
      background: red;
      flex: 1;
      border-radius: preview.$previewBorderRadius;
      display: flex;
      align-items: center;

      .preview-footer-icon {
        padding: calc(preview.$previewPadding * 0.9);
        padding-right: 0;

        svg {
          height: calc(preview.$previewVh * 2.4);
          width: auto;
        }
      }

      p {
        margin: 0;
        flex: 1;
        font-size: calc(preview.$previewVh * 1.5);
        color: #ffffff;
        font-weight: bold;
        padding: preview.$previewPadding;
      }
    }
  }
}

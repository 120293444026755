@use 'styles/colors';

.feature-editor {
  margin-top: 2rem;

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 0.9rem;
    color: colors.$darkgray;
    text-transform: uppercase;
    margin: 0;
  }

  p {
    color: colors.$lightgray;
    font-family: 'Karla', sans-serif;
    font-weight: 500;
    margin: 0.4rem 0 0.4rem 0;
  }

  .editor {
    form {
      input[type='text'] {
        display: block;
        font-size: 0.8rem;
        padding: 0.8em;
        border-radius: 0.3em;
        border: colors.$bordergray solid 0.2em;
        min-width: 20em;
        font-family: 'Montserrat', sans-serif;
        font-weight: normal;
        color: colors.$darkgray;
        margin-bottom: 0.5rem;

        &:focus {
          outline: none;
          border: colors.$primary solid 0.2em;
        }
      }

      input[type='submit'] {
        border: none;
        background: colors.$primary;
        padding: 1em 2em;
        color: colors.$white;
        border-radius: 0.2em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        text-transform: uppercase;
        font-size: 0.9rem;
        cursor: pointer;

        &:hover {
          background: darken(colors.$primary, 10%);
        }
      }
    }
  }
}

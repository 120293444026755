@use 'styles/colors';
@import 'styles/breakpoints';

.create-house-type {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }

  .create-house-type-form {
  }
}

.custom-submit {
  display: inline-block;

  p {
    border: none;
    background: colors.$primary;
    padding: 1em 2rem;
    color: #fff;
    border-radius: 0.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;
  }
}

@import 'styles/breakpoints';

.local-information {
  .modal-container {
    padding-top: 5rem;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //max-height: calc(5rem - 100vh);
    overflow-y: scroll;
    overflow-x: hidden;
    pointer-events: none;
  }

  .form-input-select {
    z-index: 10;
  }

  .select-box {
    width: 250%;
    transform: translate(-25%, 4rem);
  }

  .modal {
    pointer-events: all;
    max-width: 700px;
    // top: 2rem;
    background: white;
    z-index: 105;
    padding: 2rem;
    margin: 0 auto;

    .flex {
      .item {
        &:first-of-type {
          margin-right: 0.25rem;
        }
        &:last-of-type {
          margin-left: 0.25rem;
        }
        .form-input-select,
        .form-input-text {
          width: 100%;
          input {
            width: 100%;
          }
        }

        width: 50%;
      }
    }
  }

  .group {
    background: snow;
    padding: 0 1rem 0.5rem;
    margin-bottom: 0.7rem;
    border-radius: 3px;

    .group-header {
      padding: 1rem 0;
      display: block;

      h3 {
        margin: 0;
        padding: 0;
        float: left;
        display: inline-block;
      }
      button {
        display: inline-block;
        float: right;
      }
    }
    .item {
      border-radius: 5px;
      padding: 0 1rem;
      background: #dcdcdc;
      display: block;
      width: 100%;
      position: relative;

      button {
        position: absolute;
        top: 1em;
        right: 1em;
      }

      p {
        font-weight: bolder;
        padding: 1.2em 0;
      }
    }
  }
}

.local-information-content {
  width: 50%;

  @include media('<=1500px') {
    width: 100%;
  }
}

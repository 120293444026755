.overview {
  padding: 2rem;

  a {
    color: black;
  }

  .overview-heading {
    padding: 2rem 2rem 0 2rem;
  }
}

.quantities {
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: -2rem 0 0 0;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1000px) {
    display: block;
  }

  .card-container {
    .card.logo-container {
      height: 100%;
      background: white;
      display: flex;
      align-items: center;
      img {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}

.breakdowns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
  margin: -2rem 0 0 0;

  @media (max-width: 1000px) {
    display: block;
  }
}

.breakdown-content {
  padding: 0 2rem 2rem 2rem;

  .breakdown-section {
    display: grid;
    grid-template-columns: 2fr 1fr;

    p {
      font-family: 'Montserrat', sans-serif;
    }
  }
}

@use 'styles/colors';

.development-layout {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: row;

  .page {
    flex: 1;
    display: flex;
    flex-direction: column;

    .content {
      flex: 1;
    }
  }
}

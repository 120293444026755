@use 'styles/colors' as colors;
@import 'styles/breakpoints';

.development-group {
  padding: 1rem 0 0 0;

  .development-parent {
    .development-parent-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      .heading {
        h3 {
          margin: 0;

          @include media('<=900px') {
            margin: 0 0 1rem 0;
          }
        }
      }

      @include media('<=900px') {
        display: block;
      }
    }
  }
}

.development-region {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;

  .development-region-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;

    h3 {
      margin: 0;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 1.1rem;

      @include media('<=900px') {
        margin: 0 0 1rem 0;
      }
    }

    @include media('<=900px') {
      display: block;
    }
  }

  .no-sites {
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;

    p {
      font-size: 0.9rem;
      font-family: 'Montserrat', sans-serif;
    }
  }

  .development-region-sites {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @include media('<=1675px') {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include media('<=1400px') {
      grid-template-columns: 1fr 1fr;
    }

    @include media('<=1135px') {
      grid-template-columns: 1fr;
    }
  }
}

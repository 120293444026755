@use 'styles/preview';

.preview-individual-house-type {
  display: flex;
  flex-direction: column;

  .preview-individual-house-type-heading {
    background: red;
    color: #fff;
    padding: calc(preview.$previewPadding * 2);

    h3 {
      margin: 0;
      font-size: calc(preview.$previewVh * 2);
      // text-transform: capitalize;
    }
  }

  .preview-individual-house-split {
    display: grid;
    grid-template-columns: 6.5fr 5fr;

    .preview-individual-house-split-left {
      padding: 0 0 0 calc(preview.$previewVh * 2.5);

      .preview-individual-house-price {
        p {
          font-size: calc(preview.$previewVh * 1.5);
          font-weight: bold;
          margin: calc(preview.$previewVh * 3) 0 calc(preview.$previewVh * 1) 0;
        }
      }

      .preview-individual-house-plots {
        p {
          font-size: calc(preview.$previewVh * 1.2);
          font-weight: normal;
          margin: 0;
        }
      }

      .preview-individual-house-square-feet {
        p {
          font-size: calc(preview.$previewVh * 1.2);
          font-weight: bold;
          margin: calc(preview.$previewVh * 0.5) 0;
          color: rgb(79, 79, 79);
        }
      }

      span.preview-individual-house-break {
        height: 2px;
        width: calc(preview.$previewVh * 5);
        display: block;
        margin: calc(preview.$previewVh * 1.5) 0;
      }
    }

    .preview-house-side-cards {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: calc(preview.$previewVh * 0.5);
      margin: calc(preview.$previewVh * 3) 0;
    }
  }

  .preview-individual-house-description,
  .preview-individual-house-about {
    font-size: calc(preview.$previewVh * 1.3);

    p {
      margin: calc(preview.$previewVh * 0.4) 0;
    }

    ul {
      color: inherit;
      padding: 0 0 0 calc(preview.$previewVh * 2);

      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}

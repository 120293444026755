.option-selector-option {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 8px;

  span {
    height: 0.7rem;
    width: 0.7rem;
    border-radius: 1rem;
  }

  p {
    text-transform: capitalize;
    font-weight: normal;
    font-size: 0.8rem;
    margin: 0 0 0 0.5rem;
  }

  &:hover {
    background: darken(#f1f1f1, 10%);

    p {
      color: #27a1fc;
    }

    span {
      background: #27a1fc !important;
    }
  }
}

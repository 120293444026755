@use 'styles/colors';

.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z' fill='%232739fc' fill-opacity='.4' fill-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 100px;
  background-color: colors.$primary;
  justify-items: center;
  align-items: center;

  a {
    color: colors.$white;
  }

  .form-container {
    max-width: 500px;
    margin: 0 auto;

    p {
      color: colors.$white;
      text-align: center;
      font-size: 1.1em;
    }

    h1 {
      display: block;
      padding-bottom: 1rem;
      width: 100%;
      border-bottom: 2px solid colors.$bordergray;
      font-weight: normal;
    }

    .form-background {
      border-radius: 0.5em;
      padding: 2rem 3rem 3rem;
      background: white;
    }

    label {
      display: block;
      margin-top: 1.5rem;
      font-size: 0.9rem;
    }

    input {
      display: block;
      width: 100%;
      padding: 0.6rem;
      margin-top: 0.5rem;
      font-family: 'Karla', sans-serif;
      border-radius: 0.3em;
      border: 0.1em colors.$bordergray solid;

      &:focus {
        outline: colors.$primary solid 0.1em;
      }
    }

    input[type='submit'] {
      margin-top: 2rem;
      padding: 0.75rem;
      border-radius: 0.3em;
      border: none;
      background: colors.$primary;
      color: colors.$white;
      font-weight: bold;
    }
  }
}

@use 'styles/preview';

.preview-text-padding {
  margin: 1rem;
}

.preview-individual-house-type {
  display: flex;
  flex-direction: column;

  .preview-individual-house-type-heading {
    background: red;
    color: #fff;

    padding: calc(preview.$previewPadding * 2);

    h3 {
      margin: 0;
      font-size: calc(preview.$previewVh * 2);
      // text-transform: capitalize;
    }
  }

  .preview-individual-house-split {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .preview-individual-house-description,
  .preview-individual-house-about {
    font-size: calc(preview.$previewVh * 1.3);

    p {
      margin: calc(preview.$previewVh * 0.4) 0;
    }

    ul {
      color: inherit;
      padding: 0 0 0 calc(preview.$previewVh * 2);

      li {
        margin: 0;
        padding: 0;
      }
    }
  }
}
